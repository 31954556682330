export const locale = {
  G: {
    LOGOUT: "Sair",
    COOKIE: "sem cookie | um cookie | {n} cookies",
    MESSAGE: "sem mensagem | uma mensagem | {n} mensagens",
    NOTIFICATION: "sem notificação | uma notificação | {n} notificações",
    PLACEHOLDER: {
      DISABLED: "Opção não disponível para o plano atual"
    }
  },
  STATUS: {
    active: "Ativo",
    await: "Esperando"
  },
  MENU: {
    NEW: "new",
    ACTIONS: "Actions",
    LIST: [
      {
        entity: "DASHBOARD",
        link: "/"
      },
      {
        entity: "SITES",
        link: "/sites"
      },
      // {
      //   entity: "ACTIVITIES",
      //   link: "/activities"
      // },
      {
        entity: "INTEGRATIONS",
        link: "/integrations"
      },
      {
        entity: "INVOICES",
        link: "/invoices",
        new: true
      },
      {
        entity: "PLANS",
        link: "/plans",
        new: true
      }
    ]
  },
  LAYOUT: {
    HEADER: {
      USER: {
        TITLE: "Perfil",
        MENU: {
          PROFILE: {
            TITLE: "Meu Perfil",
            DESCRIBE: "Configurações da conta e mais"
          },
          MESSAGES: {
            TITLE: "Minhas Mensagens",
            DESCRIBE: "Caixa de entrada e tarefas"
          },
          ACTIVITIES: {
            TITLE: "Minhas Atividades",
            DESCRIBE: "Logs e Notificações"
          },
          TASKS: {
            TITLE: "Minhas Tarefas",
            DESCRIBE: "Últimas tarefas e projetos"
          }
        }
      }
    },
    FOOTER: {
      SITE: {
        TITLE: "CONSENSU.IO",
        URL: "https://consensu.io/"
      },
      ABOUT: {
        TITLE: "SOBRE",
        URL: "https://consensu.io/about"
      },
      TEAM: {
        TITLE: "TIME",
        URL: "https://consensu.io/team"
      },
      CONTACT: {
        TITLE: "CONTATO",
        URL: "https://consensu.io/contact"
      }
    }
  },
  ENTITY: {
    DASHBOARD: {
      TITLE: "Dashboard",
      ICON: "media/svg/icons/Media/Equalizer.svg"
    },
    CONSENTS: {
      TITLE: "Consentimentos",
      ICON: "media/svg/icons/General/Clipboard.svg"
    },
    SITES: {
      TITLE: "Sites",
      ICON: "media/svg/icons/General/Clipboard.svg"
    },
    SCANS: {
      TITLE: "Scans",
      ICON: "media/svg/icons/General/Binocular.svg"
    },
    ACTIVITIES: {
      TITLE: "Atividades",
      ICON: "media/svg/icons/General/User.svg"
    },
    PRIVACY: {
      TITLE: "Política de Privacidade",
      ICON: "media/svg/icons/General/Shield-check.svg"
    },
    INTEGRATIONS: {
      TITLE: "Integrações",
      ICON: "media/svg/icons/General/Attachment1.svg"
    },
    SETTINGS: {
      TITLE: "Configurações",
      ICON: "media/svg/icons/General/Settings-1.svg"
    },
    INVOICES: {
      TITLE: "Faturas",
      ICON: "media/svg/icons/Shopping/Money.svg"
    },
    PLANS: {
      TITLE: "Planos",
      ICON: "media/svg/icons/Home/Library.svg"
    }
  },
  PAGES: {
    LOGIN: {
      SIGNIN: {
        TITLE: "Entrar",
        CALL_CREATE_ACCOUNT: "Não tem uma conta?",
        CREATE_ACCOUNT: "Crie uma grátis.",
        FORGOT_PASSWORD: "Esqueceu sua senha?",
        SUBMIT: "ENTRAR"
      },
      SIGNUP: {
        TITLE: "Cadastro de conta",
        SUBTITLE: "Preencha seus dados para criar sua conta",
        AGREE: "Você concorda com os",
        TERMS: "Termos e Condições",
        SUBMIT: "CRIAR",
        CANCEL: "CANCELAR"
      },
      FORGOTTEN_PASSWORD: {}
    }
  },
  FORM: {
    EMAIL: {
      TITLE: "Email",
      PLACEHOLDER: "Email",
      ERROR: {
        EMPTY: "Email é um campo obrigatório.",
        VALID: "Por favor preencha com um email válido."
      },
      TYPE: "email"
    },
    PASSWORD: {
      TITLE: "Senha",
      PLACEHOLDER: "Senha",
      ERROR: {
        EMPTY: "Senha é um campo obrigatório."
      },
      TYPE: "password"
    },
    CONFIRM_PASSWORD: {
      TITLE: "Confirmação da senha",
      PLACEHOLDER: "Confirmação da senha",
      ERROR: {
        EMPTY: "Confirmação da senha é um campo obrigatório.",
        NOT_SAME: "A senha e sua confirmação não são iguais."
      },
      TYPE: "password"
    },
    NAME: {
      TITLE: "Nome",
      PLACEHOLDER: "Nome",
      ERROR: {
        EMPTY: "Nome é um campo obrigatório."
      },
      TYPE: "text"
    },
    WEBSITE: {
      TITLE: "Website",
      PLACEHOLDER: "Website",
      ERROR: {
        EMPTY: "Website é um campo obrigatório.",
        URI: "Website inválido. Confira se incluiu o https:// em seu domínio."
      },
      TYPE: "text"
    },
    AGREE: {
      TITLE: "Aceito",
      PLACEHOLDER: "Aceito",
      ERROR: {
        EMPTY: "Você deve concordar com os termos e condições"
      },
      TYPE: "checkbox"
    }
  },
  ALERTS: {
    INVALID_FORM: {
      title: "Dados Incorretos",
      text: "Por favor preencha os dados corretamente.",
      icon: "error",
      heightAuto: false
    },
    WRONG_PASSWORD: {
      title: "Senha Incorreta",
      text: "Por favor preencha os dados corretamente.",
      icon: "error",
      heightAuto: false
    },
    WRONG_CURRENT_PASSWORD: {
      title: "Senha Incorreta",
      text: "Senha corrente não está correta.",
      icon: "error",
      heightAuto: false
    },
    USER_NOT_FOUND: {
      title: "Usuário não encontrado",
      text: "Por favor preencha os dados corretamente.",
      icon: "error",
      heightAuto: false
    },
    DUPLICATE_KEY: {
      title: "Dados duplicados",
      text: "Por favor preencha os dados corretamente.",
      icon: "error",
      heightAuto: false
    }
  },
  STEPS: {
    1: {
      description: "EM ANALISE",
      percent: "20%",
      class: "danger"
    },
    2: {
      description: "CONFIGURE OS COOKIES",
      percent: "40%",
      class: "danger"
    },
    3: {
      description: "CONFIGURE O BANNER",
      percent: "60%",
      class: "info"
    },
    4: {
      description: "PUBLIQUE",
      percent: "80%",
      class: "info"
    },
    5: {
      description: "PUBLICADO",
      percent: "100%",
      class: "success"
    }
  },
  TRANSLATOR: {
    SELECT: "Select your language"
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact"
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In"
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset."
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered."
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username"
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid"
    }
  },
  DOWNGRADE: {
    LIST:
      "Você precisa selecionar os sites que ficaram ativos | Você precisa selecionar o único site que ficará ativo | Você precisa selecionar  {count} sites que ficaram ativos "
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted"
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated"
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created"
      }
    }
  },
  WIDGETS: {
    TABLE_CONSENTS: {
      HEADER: {
        UNIQUE_ID: "Identificação única",
        DATE: "Data",
        CATEGORY: "Categorias",
        CONSENSUS: "Consentiu",
        DETAILS: "Detalhes"
      }
    }
  }
};
