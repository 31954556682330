import numeral from "numeral";

numeral.register("locale", "ptBr", {
  delimiters: {
    thousands: ".",
    decimal: ","
  },
  abbreviations: {
    thousand: "mil",
    million: "mi",
    billion: "bi",
    trillion: "tri"
  },
  ordinal: function(number) {
    return number === 1 ? "er" : "ème";
  },
  currency: {
    symbol: "R$ "
  }
});

numeral.locale("ptBr");

export default numeral;
