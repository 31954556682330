import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const SELF_LOGIN = "selfLogin";

export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updatePassword";
export const UPDATE_USER = "updateUser";
export const ADD_CARD = "addCard";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_USER = "setUserData";
export const SET_CARDS = "setCard";
export const ADD_CARDS = "addCard";

const state = {
  errors: null,
  user: {},
  cards: [],
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  currentCards(state) {
    return state.cards;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [UPDATE_USER](context, user) {
    // console.log(context);
    // console.log(user);
    context.commit(SET_USER, user);
  },
  [SELF_LOGIN](context, token) {
    return new Promise((resolve, reject) => {
      let decoded = JwtService.decodeToken(token.replace("Bearer ", ""));
      decoded.token = token;
      if (decoded) {
        context.commit(SET_AUTH, decoded);
        resolve(decoded);
      } else {
        reject(false);
      }
    });
  },
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("auth/login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          // console.log("DATA", data);
          ApiService.post("cards/all", {
            owner: data._id,
          }).then((result) => {
            context.commit(SET_CARDS, result.data.docs);
          });
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response.data);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("auth/register", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response.data);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.query("auth/verify")
        .then((data) => {
          context.commit(SET_AUTH, data.data);
          ApiService.post("cards/all", {
            query: {
              owner: data.data._id,
            },
          }).then((result) => {
            context.commit(SET_CARDS, result.data.docs);
          });
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;
    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [ADD_CARD](context, payload) {
    context.commit(ADD_CARDS, payload);
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [SET_USER](state, user) {
    state.user.name = user.name;
    state.user.email = user.email;
    state.user.phone = user.phone;
    console.log("STATE USER", state.user);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [ADD_CARDS](state, card) {
    state.cards.push(card);
  },
  [SET_CARDS](state, card) {
    state.cards = card;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
