import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import VueTheMask from "vue-the-mask";
import numeral from "@/core/plugins/numerals";
import events from "events-vue-allin";
// import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
// import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@/core/plugins/treeselect";
import "@mdi/font/css/materialdesignicons.css";
import { SELF_LOGIN } from "@/core/services/store/auth.module";
// API service init
ApiService.init();

// Remove this to disable mock API
// MockService.init();
// setUser

if (localStorage.getItem("auth-token")) {
  store.dispatch(SELF_LOGIN, localStorage.getItem("auth-token"));
}

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([
    store.dispatch(VERIFY_AUTH, localStorage.getItem("auth-token"))
  ]).then(next);

  // reset config to initial state
  // store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

import VueTippy, { TippyComponent } from "vue-tippy";
import "tippy.js/themes/google.css";
Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

Vue.use(VueTheMask);
Vue.use(events);
// Vue.use(vueNumeralFilterInstaller, { locale: "pt-br" });
Vue.prototype.numeral = numeral;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  events,
  numeral,
  render: h => h(App)
}).$mount("#app");
